<template>
  <div>
    <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>方案管理</el-breadcrumb-item>
      <el-breadcrumb-item>反馈表审核方案</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="add">
      <el-select filterable v-model="select" placeholder="选择审核状态" style="margin-right: 10px;width: 150px;" size="small">
        <el-option label="未审核" value="Y"></el-option>
        <el-option label="审核" value="C"></el-option>
      </el-select>
      <el-date-picker v-model="date" type="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" style="width:250px;margin-right:10px;" size="small">
      </el-date-picker>
      <el-button type="primary" @click="getdata(1)" size="small">查询</el-button>
    </div>
    <el-table :data="list" border style="width: 100%">
      <el-table-column prop="id" v-if="false"></el-table-column>
      <el-table-column prop="KID" label="反馈表编号"></el-table-column>
      <el-table-column prop="Dis_Name" label="反馈表名"></el-table-column>
      <el-table-column label="用户ID" prop="Cust_ID"></el-table-column>
      <el-table-column label="用户姓名" prop="Cust_Name"></el-table-column>
      <el-table-column label="服务人ID" prop="Input_ID"></el-table-column>
      <el-table-column label="医生姓名" prop="doc_name"></el-table-column>
      <el-table-column fixed="right" width="180">
        <template slot-scope="scope">
          <div class="opt doc">
            <p>
              <el-button size="mini" @click="lookplan(scope.row)">查看</el-button>
              <el-button type="danger" size="mini" @click="dhplan(scope.row)">打回</el-button>
            </p>
            <p>
              <el-button v-if="scope.row.trans_flag == 'Y'" type="success" size="mini" @click="shplan(scope.row)">审核</el-button>
              <!--  v-if="scope.row.trans_flag == 'C'" -->
            </p>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <p class="paging">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current" :page-sizes="pageSizes" :page-size="PageSize" layout="total, sizes, prev, pager, next, jumper" :total="totalCount" :hide-on-single-page="value"></el-pagination>
    </p>
    <feedback :lookfeedback.sync="lookfeedback" :feedbackid="feedbackid" :feedbacknoedit="feedbacknoedit" :iskefu="'no'"></feedback>
  </div>
</template>
<script>
import feedback from "../components/feedback.vue";
export default {
  inject: ["reload"],
  data() {
    return {
      list: [],
      current: 1, //默认显示第几页
      totalCount: 0, // 总条数，根据接口获取数据长度
      pageSizes: [20], // 个数选择器（可修改）
      PageSize: 20, // 默认每页显示的条数（可修改）
      value: false,
      date: "",
      select: "",
      lookfeedback: false,
      feedbackid: "",
      feedbacknoedit: "",
    };
  },
  components: {
    feedback,
  },
  mounted() {},
  methods: {
    handleSizeChange() {},
    handleCurrentChange(currentPage) {
      this.getdata(currentPage);
    },
    getdata(page) {
      var str = "";
      if (this.date.length > 0 && this.date) {
        str = "&fa_datetime1=" + this.date[0] + "&fa_datetime2=" + this.date[1];
      }
      this.axios
        .get(
          "/field/get_z_newback_mains_audit_fa?page=" +
            page +
            "&size=" +
            this.PageSize +
            "&trans_flag=" +
            this.select +
            str
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.list = res.data.result;
            this.totalCount = res.data.count;
          } else {
            this.list = [];
            this.totalCount = 0;
          }
        });
    },
    shplan(row) {
      this.$confirm("此操作将让患者看到方案信息, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios
            .post(
              "/field/update_z_newback_main_audit_fa",
              this.qs.stringify({
                kid: row.KID,
                uid: sessionStorage.getItem("servicer_id"),
              })
            )
            .then((res) => {
              if (res.data.code == 0) {
                // this.$message({
                //   type: "success",
                //   message: "审核成功",
                // });
                // // this.reload();
                // this.getdata(1);

                this.axios
                  .post(
                    "/order/insert_gu_docpres_details_temp",
                    this.qs.stringify({ kid: row.KID })
                  )
                  .then((res) => {
                    console.log(res);
                    if (res.data.code == 0) {
                      this.$message({
                        type: "success",
                        message: "审核成功",
                      });
                      this.getdata(1);
                    } else {
                      this.$message({
                        type: "error",
                        message: res.data.msg,
                      });
                    }
                  });
              } else {
                this.$message({
                  type: "error",
                  message: res.data.msg,
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消审核",
          });
        });
    },
    dhplan(row) {
      this.$confirm("此操作将打回该方案, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios
            .post(
              "/field/update_z_newback_main_state",
              this.qs.stringify({
                state: 2,
                kid: row.KID,
                uid: sessionStorage.getItem("servicer_id"),
              })
            )
            .then((res) => {
              if (res.data.code == 0) {
                this.$message({
                  type: "success",
                  message: "打回成功",
                });
                this.getdata(1);
              } else {
                this.$message({
                  type: "error",
                  message: res.data.msg,
                });
              }
            });
          // this.axios
          //   .post(
          //     "/gu/update_t_hytl_ta_transflag",
          //     this.qs.stringify({
          //       kid: row.KID,
          //       uid: sessionStorage.getItem("servicer_id"),
          //       trans_flag: "S",
          //     })
          //   )
          //   .then((res) => {
          //     if (res.data.code == 0) {
          //       this.$message({
          //         type: "success",
          //         message: "打回成功",
          //       });
          //       this.reload();
          //     } else {
          //       this.$message({
          //         type: "error",
          //         message: res.data.msg,
          //       });
          //     }
          //   });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消审核",
          });
        });
    },
    lookplan(row) {
      // sessionStorage.setItem("Cust_IDsh", row.Cust_ID);
      // let routeData = this.$router.resolve({
      //   path: "/FKDetail",
      //   query: { id: row.KID },
      // });
      // window.open(routeData.href, "_blank");
      this.lookfeedback = true;
      this.feedbackid = row.KID;
      this.feedbacknoedit = false;
    },
  },
};
</script>
<style>
.Bdh {
  height: 50px;
  width: 100%;
  line-height: 40px;
}

.add {
  width: 100%;
  height: 50px;
  text-align: left;
}
</style>
